import { Box, Card, Text, Button, Stack, Flex, Group } from '@mantine/core'
import Link from 'next/link'
import Image from 'next/image'
import ImageFallback from '../image_fallback'
import { useTranslations } from 'next-intl'

export default function CountryCard({
  country,
  countryCode,
  locale,
  filteredCountryHits
}) {
  const t = useTranslations('Index')
  const t_genres = useTranslations('Genres')

  const generateContentString = (contentType, genre, year) => {
    let contentString = contentType
    if (genre) contentString += ` | ${genre}`
    if (year) contentString += ` | ${year}`
    return contentString
  }

  return (
    <Box sx={{ width: '100%', marginBottom: '32px' }}>
      <Box
        sx={{
          backgroundColor: '#0E1922',
          padding: '12px',
          borderRadius: '4px',
          marginBottom: '8px',
          boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset',
          textAlign: 'center'
        }}
      >
        <Text
          size="sm"
          tt="uppercase"
          fw={500}
          sx={{
            color: 'rgba(123,57,182,1)',
            fontSize: '12px',
            letterSpacing: '0.5px'
          }}
        >
          {t('topSeriesAndMovies')}
        </Text>
        <Link
          href={`/${locale}/hits/${countryCode}`}
          style={{ textDecoration: 'none' }}
        >
          <Text
            size="xl"
            tt="uppercase"
            fw={200}
            color="white"
            sx={{
              fontSize: '20px',
              marginTop: '4px',
              letterSpacing: '0.5px',
              position: 'relative',
              display: 'inline-block',
              paddingBottom: '4px',
              cursor: 'pointer',
              '&:after': {
                content: '""',
                position: 'absolute',
                width: '0',
                height: '2px',
                bottom: '0',
                left: '50%',
                top: '80%',
                backgroundColor: '#7B39B6',
                transition: 'all 0.3s ease',
                transform: 'translateX(-50%)'
              },
              '&:hover:after': {
                width: '100%'
              }
            }}
          >
            {country}
          </Text>
        </Link>
      </Box>

      <Card
        sx={{
          overflow: 'visible',
          maxWidth: '100%'
        }}
        p={0}
      >
        <Card.Section
          sx={{
            width: '100%',
            margin: 0,
            padding: 0,
            height: '170px',
            position: 'relative'
          }}
        >
          <ImageFallback
            src={
              filteredCountryHits[0]?.backdrop
                ? `https://wsrv.nl/?url=${filteredCountryHits[0].backdrop}&w=600&output=webp&q=100&il=1`
                : '/noimage.png'
            }
            fallbackSrc="/noimage.png"
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              borderRadius: '4px'
            }}
            alt="movie poster"
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset',
              borderRadius: '4px'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: '5px',
                justifyContent: 'flex-end',
                textAlign: 'right'
              }}
            >
              <Text
                color="white"
                tt="uppercase"
                fw={700}
                size={12}
                sx={{
                  display: 'block'
                }}
              >
                {filteredCountryHits[0]?.title
                  ? filteredCountryHits[0].title.length > 30
                    ? filteredCountryHits[0].title.substring(0, 30) + '...'
                    : filteredCountryHits[0].title
                  : '-'}
              </Text>
            </Box>
            {filteredCountryHits[0]?.content_type?.toLowerCase() === 'series' && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 10,
                  padding: '5px',
                  backgroundColor: 'rgba(123,57,182,1)',
                  borderRadius: '0 0 5px 5px',
                  display: 'flex',
                  placeContent: 'center'
                }}
                w={21}
                h={19}
              >
                <Text color="white" size={10} fw={700} p={0}>
                  TV
                </Text>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: -30,
              right: 0,
              padding: '5px'
            }}
          >
            <Link
              href={`/${locale}/hits/${countryCode}`}
              style={{ textDecoration: 'none' }}
            >
              <div
                style={{
                  width: 63,
                  height: 63,
                  borderRadius: '8px',
                  overflow: 'hidden',
                  backgroundColor: '#0E1922',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Image
                  src={`https://kapowaz.github.io/square-flags/flags/${countryCode.toLowerCase()}.svg`}
                  width={130}
                  height={65}
                  alt={`${country} flag`}
                  style={{
                    objectFit: 'contain'
                  }}
                />
              </div>
            </Link>
          </Box>
        </Card.Section>
        <Stack
          p="md"
          sx={{
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}
        >
          {Array(5)
            .fill(null)
            .map((_, index) =>
              filteredCountryHits[index] ? (
                <Link
                  key={filteredCountryHits[index].uid}
                  href={`/${filteredCountryHits[index].content_type.toLowerCase()}/${
                    filteredCountryHits[index].slug || filteredCountryHits[index].uid
                  }`}
                  passHref
                  prefetch={false}
                >
                  <Flex align="center" sx={{ cursor: 'pointer' }}>
                    <Group spacing={0} sx={{ minWidth: '30%' }} align="baseline">
                      <Text
                        fw={800}
                        color="rgba(123,57,182,1)"
                        size="35px"
                        sx={{
                          lineHeight: 0.9,
                          marginLeft: index === 0 ? '4px' : '0'
                        }}
                      >
                        {index + 1}
                      </Text>
                      <div
                        style={{
                          width: '45px',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <ImageFallback
                          src={`https://wsrv.nl/?url=${filteredCountryHits[index].poster}&w=140&output=webp&q=100&il=1`}
                          fallbackSrc="/poster-placeholder-4.png"
                          width={45}
                          height={67}
                          alt="poster"
                          style={{
                            borderRadius: '4px',
                            ...(filteredCountryHits[index].poster
                              ? {
                                  objectFit: 'cover'
                                }
                              : {
                                  objectFit: 'contain'
                                })
                          }}
                          priority={false}
                        />
                      </div>
                    </Group>
                    <Stack spacing={4}>
                      <Text
                        color="white"
                        tt="uppercase"
                        fw={400}
                        size={14}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          maxWidth: '180px'
                        }}
                      >
                        {filteredCountryHits[index].title}
                      </Text>
                      {filteredCountryHits[index].score && (
                        <Flex align="center" mt={2}>
                          <Text
                            size={10}
                            weight={700}
                            sx={{
                              backgroundColor: 'rgba(123,57,182,0.2)',
                              padding: '2px 6px',
                              borderRadius: '4px',
                              color: 'rgba(123,57,182,1)',
                              letterSpacing: '0.5px',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px'
                            }}
                          >
                            <Image
                              src="/scorestar.png"
                              width={10}
                              height={10}
                              alt="Score star"
                            />
                            {Number(filteredCountryHits[index].score).toFixed(1)}
                          </Text>
                        </Flex>
                      )}
                      <Text
                        color="#7B39B6"
                        tt="uppercase"
                        fw="bolder"
                        size={10}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          maxWidth: '180px',
                          marginTop: '2px'
                        }}
                      >
                        {generateContentString(
                          t(filteredCountryHits[index].content_type),
                          t_genres(filteredCountryHits[index].genre),
                          filteredCountryHits[index].year
                        )}
                      </Text>
                    </Stack>
                  </Flex>
                </Link>
              ) : (
                <Flex key={`empty-${index}`} align="center">
                  <Group spacing={0} sx={{ minWidth: '30%' }} align="baseline">
                    <Text
                      fw={800}
                      color="rgba(123,57,182,1)"
                      size="35px"
                      sx={{ lineHeight: 0.9 }}
                    >
                      {index + 1}
                    </Text>
                    <div
                      style={{
                        width: '45px',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <ImageFallback
                        src="/poster-placeholder-4.png"
                        width={45}
                        height={67}
                        alt="empty poster"
                        style={{
                          borderRadius: '4px',
                          objectFit: 'contain',
                          opacity: 0.3
                        }}
                      />
                    </div>
                  </Group>
                  <Stack spacing={4}>
                    <Text color="white" tt="uppercase" fw={400} size={14}>
                      -
                    </Text>
                    <Text color="#7B39B6" tt="uppercase" fw="bolder" size={10}>
                      -
                    </Text>
                  </Stack>
                </Flex>
              )
            )}

          {Array(5)
            .fill(null)
            .map((_, index) =>
              filteredCountryHits[index + 5] ? (
                <Link
                  key={filteredCountryHits[index + 5].uid}
                  href={`/${filteredCountryHits[
                    index + 5
                  ].content_type.toLowerCase()}/${
                    filteredCountryHits[index + 5].slug ||
                    filteredCountryHits[index + 5].uid
                  }`}
                  passHref
                  prefetch={false}
                >
                  <Flex
                    align="center"
                    sx={{
                      cursor: 'pointer',
                      marginTop: '-8px'
                    }}
                  >
                    <Group spacing={0} sx={{ minWidth: '30%' }} align="baseline">
                      <Text
                        fw={800}
                        color="rgba(123,57,182,1)"
                        size="20px"
                        sx={{
                          lineHeight: 0.9,
                          marginLeft: '4px',
                          width: '20px',
                          textAlign: 'left',
                          ...(index === 4 && {
                            marginLeft: '-3px'
                          })
                        }}
                      >
                        {index + 6}
                      </Text>
                    </Group>
                    <Text
                      color="white"
                      tt="uppercase"
                      fw={400}
                      size={14}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block',
                        maxWidth: '180px',
                        marginLeft: '-35px'
                      }}
                    >
                      {filteredCountryHits[index + 5].title}
                    </Text>
                  </Flex>
                </Link>
              ) : (
                <Flex
                  key={`empty-bottom-${index}`}
                  align="center"
                  sx={{ marginTop: '-8px' }}
                >
                  <Group spacing={0} sx={{ minWidth: '30%' }} align="baseline">
                    <Text
                      fw={800}
                      color="rgba(123,57,182,1)"
                      size="20px"
                      sx={{
                        lineHeight: 0.9,
                        marginLeft: '4px',
                        width: '20px',
                        textAlign: 'left',
                        ...(index === 4 && {
                          marginLeft: '-3px'
                        })
                      }}
                    >
                      {index + 6}
                    </Text>
                  </Group>
                  <Text
                    color="white"
                    tt="uppercase"
                    fw={400}
                    size={14}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                      maxWidth: '180px',
                      marginLeft: '-35px'
                    }}
                  >
                    -
                  </Text>
                </Flex>
              )
            )}

          <Button
            mt="xs"
            size="xs"
            fullWidth
            component={Link}
            href={`/${locale}/hits/${countryCode}`}
            sx={{
              backgroundColor: '#7B39B6',
              color: 'white',
              fontSize: '10px',
              fontWeight: 'bold',
              height: 'auto',
              padding: '2px',
              '&:hover': {
                backgroundColor: '#6a2ca6'
              }
            }}
          >
            <Stack spacing={2} align="center">
              <Text>{t('seeMore')}</Text>
              <Text>{country?.toUpperCase()}</Text>
            </Stack>
          </Button>
        </Stack>
      </Card>
    </Box>
  )
}
