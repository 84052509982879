import { Button, Group } from '@mantine/core'
import { useTranslations } from 'next-intl'
import styles from '../../styles/Home.module.css'

export default function ContentTypeFilter({ contentFilter, setContentFilter }) {
  const t = useTranslations('Index')

  return (
    <Group
      spacing={0}
      sx={{
        flexShrink: 0,
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 768px)': { width: '100%' }
      }}
    >
      {['all', 'movie', 'serie'].map((filter, index) => (
        <Button
          key={filter}
          variant="unstyled"
          size={{ base: 'xs', sm: 'sm' }}
          onClick={() => setContentFilter(filter)}
          className={`${styles.button} ${
            contentFilter === filter ? styles.buttonActive : styles.buttonInactive
          }`}
          sx={{
            boxShadow: 'inset 0px -3px 0px -1px #7b39b6',
            width: '80px',
            flexShrink: 0,
            fontSize: { base: '12px', sm: '14px' },
            borderRadius: ['4px 0 0 4px', '0', '0 4px 4px 0'][index]
          }}
        >
          {t(filter === 'all' ? 'all' : filter + 's')}
        </Button>
      ))}
    </Group>
  )
}
