import { Carousel } from '@mantine/carousel'
import { Box, Flex, Group, Loader, Stack, Text } from '@mantine/core'
import { useTranslations } from 'next-intl'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import TitleDivider from '../components/divider'
import ContentTypeFilter from '../components/home/ContentTypeFilter'
import CountryCard from '../components/home/CountryCard'
import PlatformCard from '../components/home/PlatformCard'
import ImageFallback from '../components/image_fallback'
import CountryInput from '../components/inputs/country_input'
import Layout from '../components/layout'
import { usePlatforms } from '../context/platforms'
import styles from '../styles/Home.module.css'
import { searchAPI_v2 } from '../utils/axios'
import countries from '../utils/countries.json'
import prerenderImportantLocales from '../utils/prerender_locales.js'
import { hitsCountries } from '../components/platform_details.js'

const fetcher = (url) => fetch(url).then((res) => res.json())

// Crear un componente separado para la plataforma
const Platform = memo(({ platform, isSelected, onToggle }) => {
  return (
    <div
      key={platform.id}
      className={`platform-item ${isSelected ? 'selected' : ''}`}
      onClick={() => onToggle(platform.id)}
    >
      <Image src={platform.logo} alt={platform.name} width={24} height={24} />
      <span>{platform.name}</span>
    </div>
  )
})

Platform.displayName = 'Platform'

export default function Home({
  title,
  initialPlatformHits,
  countryHits,
  initialAllPlatforms = []
}) {
  const { locale } = useRouter()
  const countryCode = locale.split('-')[1]?.toLowerCase()
  const { selectedPlatforms } = usePlatforms()

  const [platformHits, setPlatformHits] = useState(initialPlatformHits)
  const [allPlatforms, setAllPlatforms] = useState(initialAllPlatforms)
  const [hasMore, setHasMore] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [contentFilter, setContentFilter] = useState('all')
  const [filteredCountryHits, setFilteredCountryHits] = useState(countryHits)

  const country = useMemo(
    () =>
      countries.find((c) => c.code === countryCode?.toUpperCase())?.name ||
      countryCode,
    [countryCode]
  )

  const t = useTranslations('Index')

  useEffect(() => {
    const filtered = countryHits.filter((item) => {
      if (contentFilter === 'all') return true
      if (contentFilter === 'serie')
        return item.content_type.toLowerCase() === 'series'
      return item.content_type.toLowerCase() === contentFilter
    })
    setFilteredCountryHits(filtered)
  }, [countryHits, contentFilter])

  const handleCountryChange = useCallback((newCountry) => {
    if (!newCountry) return

    const spanishCountries = ['ar', 'mx', 'es', 'cl', 'co', 'pe']
    const defaultLanguage = spanishCountries.includes(newCountry.toLowerCase())
      ? 'es'
      : 'en'
    const newLocale = `${defaultLanguage}-${newCountry.toUpperCase()}`
    window.location.href = `/${newLocale}`
  }, [])

  const handleContentFilterChange = useCallback((newFilter) => {
    setContentFilter(newFilter)
  }, [])

  const removeDuplicatePlatforms = useCallback((platforms) => {
    return Array.from(
      new Map(platforms.map((platform) => [platform.platform, platform])).values()
    )
  }, [])

  const loadPlatformContent = useCallback(async (params) => {
    // Primero obtenemos la lista de contenido con hits/v3
    const [moviesData, seriesData] = await Promise.all([
      fetcher(
        `/api/api_ext/content/hits/v3?country=${params.country}&type=movie&platform=${params.platform}&limit=${params.limit}`
      ),
      fetcher(
        `/api/api_ext/content/hits/v3?country=${params.country}&type=series&platform=${params.platform}&limit=${params.limit}`
      )
    ])

    const allContent = [...(moviesData.data || []), ...(seriesData.data || [])]
    const uniqueContent = Array.from(
      new Map(allContent.map((item) => [item.uid, item])).values()
    )

    // Luego obtenemos los detalles con metadata/bb para cada item
    const detailedContent = await Promise.all(
      uniqueContent.map(async (item) => {
        try {
          const metadataResponse = await fetcher(
            `/api/api_ext/content/metadata/slug?id=${
              item.slug
            }&type=${item.content_type.toLowerCase()}`
          )

          return {
            title: item.translation?.title || item.title || '',
            poster: item.poster || '',
            backdrop: item.backdrop || '',
            genre: item.genres?.[0] || '',
            year: item.year || null,
            uid: item.uid || '',
            slug: item.slug || '',
            content_type: item.content_type || '',
            hits: item.hits || 0,
            score: metadataResponse?.score || null
          }
        } catch (error) {
          console.error(`Error fetching metadata for ${item.uid}:`, error)
          return {
            title: item.translation?.title || item.title || '',
            poster: item.poster || '',
            backdrop: item.backdrop || '',
            genre: item.genres?.[0] || '',
            year: item.year || null,
            uid: item.uid || '',
            slug: item.slug || '',
            content_type: item.content_type || '',
            hits: item.hits || 0,
            score: null
          }
        }
      })
    )

    return detailedContent.sort((a, b) => b.hits - a.hits)
  }, [])

  const loadMore = useCallback(async () => {
    if (isLoadingMore) return
    setIsLoadingMore(true)

    try {
      const currentLength = Object.keys(platformHits).length
      const nextPlatforms = initialAllPlatforms.slice(
        currentLength,
        currentLength + 4
      )

      if (!nextPlatforms.length) {
        setHasMore(false)
        return
      }

      const newPlatformParams = nextPlatforms.map((platform) => ({
        country: countryCode,
        type: ['movie', 'series'],
        limit: 20,
        platform: platform.id,
        logo: platform.logo,
        language: locale.split('-')[0],
        append: 'score'
      }))

      const results = await Promise.all(newPlatformParams.map(loadPlatformContent))

      const newHits = nextPlatforms.reduce((acc, platform, index) => {
        acc[platform.platform] = {
          id: platform.id,
          data: results[index] || [],
          logo: platform.logo
        }
        return acc
      }, {})

      setPlatformHits((prev) => ({ ...prev, ...newHits }))
      setAllPlatforms((prev) => [...prev, ...nextPlatforms])
      setHasMore(currentLength + 4 < initialAllPlatforms.length)
    } catch (error) {
      console.error('Error loading more platforms:', error)
      setHasMore(false)
    } finally {
      setIsLoadingMore(false)
    }
  }, [
    countryCode,
    initialAllPlatforms,
    loadPlatformContent,
    locale,
    platformHits,
    isLoadingMore
  ])

  const sortPlatformsBySelected = useCallback((platforms, selectedIds) => {
    const sortedPlatforms = [...platforms]
    return sortedPlatforms.sort((a, b) => {
      const aIndex = selectedIds.indexOf(a.id)
      const bIndex = selectedIds.indexOf(b.id)
      if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex
      if (aIndex !== -1) return -1
      if (bIndex !== -1) return 1
      return 0
    })
  }, [])

  const sortedPlatforms = useMemo(
    () => sortPlatformsBySelected(initialAllPlatforms, selectedPlatforms),
    [initialAllPlatforms, selectedPlatforms, sortPlatformsBySelected]
  )

  // Memoizar la función getFilteredContent para evitar recreaciones
  const getFilteredContent = useCallback((content, filter) => {
    if (filter === 'all') return content
    if (filter === 'serie')
      return content.filter((item) => item.content_type.toLowerCase() === 'series')
    return content.filter((item) => item.content_type.toLowerCase() === filter)
  }, [])

  // Memoizar las plataformas visibles filtradas
  const visiblePlatforms = useMemo(() => {
    const filtered = removeDuplicatePlatforms(allPlatforms).filter((platform) => {
      const platformContent = platformHits[platform.platform]?.data || []
      const filteredContent = getFilteredContent(platformContent, contentFilter)
      return filteredContent.length > 0
    })
    return filtered
  }, [
    allPlatforms,
    platformHits,
    contentFilter,
    getFilteredContent,
    removeDuplicatePlatforms
  ])

  // Memoizar las configuraciones del carrusel
  const carouselConfig = useMemo(
    () => ({
      slideSize: { base: '40px', sm: '50px' },
      height: { base: 40, sm: 50 },
      slideGap: 'md',
      align: 'start',
      slidesToScroll: { base: 3, sm: 5 },
      withControls: initialAllPlatforms.length > 5,
      dragFree: true,
      containScroll: 'trimSnaps',
      styles: {
        control: {
          backgroundColor: '#0E1922',
          border: 'none',
          color: 'rgba(123,57,182,1)',
          '&:hover': {
            backgroundColor: '#1a2733',
            color: 'rgba(123,57,182,0.8)'
          },
          width: 30,
          minWidth: 30,
          height: '100%',
          borderRadius: 4,
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          transition: 'all 0.2s ease',
          position: 'absolute',
          top: 0,
          transform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2,
          '&[data-inactive]': {
            opacity: 0,
            cursor: 'default',
            visibility: 'hidden'
          },
          '&:first-of-type': {
            left: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            background: 'linear-gradient(90deg, #0E1922 70%, transparent)'
          },
          '&:last-of-type': {
            right: -40,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            background: 'linear-gradient(-90deg, #0E1922 70%, transparent)'
          }
        },
        viewport: {
          margin: '0'
        },
        container: {
          margin: '0'
        }
      }
    }),
    [initialAllPlatforms.length]
  )

  // Memoizar el renderizado del carrusel de plataformas
  const renderCarouselSlides = useMemo(
    () =>
      sortedPlatforms.map((platform) => (
        <Carousel.Slide key={platform.platform}>
          <Link
            href={`/${locale}/hits/${countryCode}/${platform.id}-${platform.platform
              .replace(/\s+/g, '')
              .toLowerCase()}`}
            aria-label={`Ver contenido de ${platform.platform}`}
          >
            <Box
              sx={{
                width: 50,
                height: 50,
                borderRadius: 8,
                overflow: 'hidden',
                transition: 'all 0.2s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 4px 8px rgba(123,57,182,0.2)'
                },
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: '#0E1922',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}
            >
              <ImageFallback
                src={`https://wsrv.nl/?url=${platform.logo}&w=100&output=webp&q=100&il=1`}
                fallbackSrc="/noimage.png"
                alt={`${platform.platform} logo`}
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  maxHeight: '100%'
                }}
                width={40}
                height={40}
              />
            </Box>
          </Link>
        </Carousel.Slide>
      )),
    [sortedPlatforms, locale, countryCode]
  )

  const seoMetadata = {
    title: title || t('pageTitle'),
    description: t('pageDescription'),
    url: `https://www.streamdiscover.com/${locale}`,
    image: '/path/to/your/image.jpg'
  }

  const mainContainerStyles = {
    width: '80vw',
    maxWidth: '1366px',
    margin: '0 auto'
  }

  const stackStyles = (theme) => ({
    width: '80vw',
    maxWidth: '100%',
    [theme.fn.smallerThan('md')]: { width: '85vw' },
    [theme.fn.smallerThan('sm')]: { width: '90vw' }
  })

  const groupStyles = (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    justifyContent: 'flex-start',
    '& > *': {
      flex: '0 0 calc(25% - 12px)',
      maxWidth: 'calc(25% - 12px)',
      minWidth: '250px'
    },
    [theme.fn.smallerThan(1400)]: {
      '& > *': {
        flex: '0 0 calc(33.333% - 11px)',
        maxWidth: 'calc(33.333% - 11px)'
      }
    },
    [theme.fn.smallerThan(1100)]: {
      '& > *': {
        flex: '0 0 calc(50% - 8px)',
        maxWidth: 'calc(50% - 8px)'
      }
    },
    [theme.fn.smallerThan(680)]: {
      '& > *': {
        flex: '0 0 100%',
        maxWidth: '100%'
      }
    }
  })

  return (
    <>
      <Head>
        <title>{seoMetadata.title}</title>
        <meta name="description" content={seoMetadata.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={seoMetadata.title} />
        <meta property="og:description" content={seoMetadata.description} />
        <meta property="og:url" content={seoMetadata.url} />
        <meta property="og:image" content={seoMetadata.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoMetadata.title} />
        <meta name="twitter:description" content={seoMetadata.description} />
        <meta name="twitter:image" content={seoMetadata.image} />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className={styles.main} style={mainContainerStyles}>
        <Stack w="100%" spacing={0} align="start" mt={10} sx={stackStyles}>
          <Flex justify="space-between" align="center" w="100%" mb={20}>
            <Box sx={{ width: '100%' }}>
              <TitleDivider
                titleText={
                  <Flex align="center" gap={8}>
                    <Text component="span">{t('trendingCarouselTitle')}</Text>
                    <CountryInput
                      withLabel={false}
                      onChange={handleCountryChange}
                      value={countryCode}
                      size="xs"
                      style={{ maxWidth: '150px' }}
                      icon={null}
                      uppercase={true}
                    />
                  </Flex>
                }
              />
            </Box>
          </Flex>

          <Flex
            justify="space-between"
            align="center"
            w="100%"
            mt={10}
            mb={20}
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 'md', sm: 0 }}
          >
            <Box w={{ base: '100%', sm: '60%' }} pos="relative">
              <Carousel {...carouselConfig}>{renderCarouselSlides}</Carousel>
            </Box>

            <ContentTypeFilter
              contentFilter={contentFilter}
              setContentFilter={handleContentFilterChange}
            />
          </Flex>

          <InfiniteScroll
            dataLength={allPlatforms.length}
            next={loadMore}
            hasMore={hasMore}
            loader={
              <Flex justify="center" align="center" w="100%" my="xl" gap="md">
                <Text>{t('loadingMore')}</Text>
                <Loader color="rgba(123,57,182,1)" size="sm" variant="dots" />
              </Flex>
            }
          >
            <Group position="apart" w="100%" my={24} spacing={0} sx={groupStyles}>
              <CountryCard
                country={country}
                countryCode={countryCode}
                locale={locale}
                filteredCountryHits={filteredCountryHits}
              />
              {visiblePlatforms.map((platform) => {
                const platformData = platformHits[platform.platform]
                const filteredData = platformData?.data?.filter((item) => {
                  if (contentFilter === 'all') return true
                  if (contentFilter === 'serie')
                    return item.content_type.toLowerCase() === 'series'
                  return item.content_type.toLowerCase() === contentFilter
                })

                return (
                  <PlatformCard
                    key={platform.platform}
                    platform={platform}
                    platformData={platformData}
                    locale={locale}
                    countryCode={countryCode}
                    contentFilter={contentFilter}
                    filteredData={filteredData}
                  />
                )
              })}
            </Group>
          </InfiniteScroll>
        </Stack>
      </main>
    </>
  )
}

Home.propTypes = {
  title: PropTypes.string,
  initialPlatformHits: PropTypes.object,
  countryHits: PropTypes.array,
  initialAllPlatforms: PropTypes.array
}

Home.getLayout = function getLayout(page) {
  return (
    <Layout showSearchBar withAutocomplete showHero={true} pageName="Index">
      {page}
    </Layout>
  )
}

export async function getStaticProps({ locale }) {
  const countryCode = locale.split('-')[1]?.toLowerCase()
  const language = locale.split('-')[0]?.toLowerCase()

  prerenderImportantLocales(locale)

  const isCountryOnHits = hitsCountries.some(
    (c) => c.code === countryCode?.toUpperCase()
  )

  try {
    // Aumentamos el límite a 50 plataformas
    const { data: response } = await searchAPI_v2.get(
      `platforms/top?country=${countryCode}&page=1&limit=50`
    )

    if (!response?.data?.length) {
      throw new Error('No platforms found')
    }

    // Filtrar plataformas duplicadas antes de procesar
    const uniquePlatforms = Array.from(
      new Map(
        response.data.map((platform) => [platform.platform, platform])
      ).values()
    )

    // Tomamos solo las primeras 7 plataformas para el procesamiento inicial
    const initialPlatforms = uniquePlatforms.slice(0, 7)

    const platformParams = initialPlatforms.map((platform) => ({
      country: countryCode,
      type: ['movie', 'series'],
      limit: 10,
      platform: platform.id,
      logo: platform.logo || '',
      language: language,
      append: 'score'
    }))

    const results = await Promise.all(
      platformParams.map(async (params) => {
        try {
          // Primero obtenemos la lista con hits/v3
          const [moviesData, seriesData] = await Promise.all([
            searchAPI_v2.get('content/hits/v3', {
              params: { ...params, type: 'movie' }
            }),
            searchAPI_v2.get('content/hits/v3', {
              params: { ...params, type: 'series' }
            })
          ])

          const parsedMovies = (moviesData?.data?.data || []).map((movie) => ({
            title: movie.translation?.title || movie.title || '',
            poster: movie.poster || '',
            backdrop: movie.backdrop || '',
            genre: movie.genres?.[0] || '',
            year: movie.year || null,
            uid: movie.uid || '',
            slug: movie.slug || '',
            content_type: movie.content_type || '',
            hits: movie.hits || 0,
            score: movie.score || null
          }))

          const parsedSeries = (seriesData?.data?.data || []).map((series) => ({
            title: series.translation?.title || series.title || '',
            poster: series.poster || '',
            backdrop: series.backdrop || '',
            genre: series.genres?.[0] || '',
            year: series.year || null,
            uid: series.uid || '',
            slug: series.slug || '',
            content_type: series.content_type || '',
            hits: series.hits || 0,
            score: series.score || null
          }))

          // Filtrar duplicados
          const allContent = [...parsedMovies, ...parsedSeries]
          const uniqueContent = Array.from(
            new Map(
              allContent.map((item) => [`${item.uid}-${item.title}`, item])
            ).values()
          )

          // Luego obtenemos los detalles con metadata/bb
          const detailedContent = await Promise.all(
            uniqueContent.map(async (item) => {
              try {
                const response = await searchAPI_v2.get('content/metadata/bb', {
                  params: {
                    id: item.uid,
                    type: item.content_type.toLowerCase(),
                    append: 'credits,score',
                    language: params.language
                  }
                })
                return {
                  ...item,
                  score: response.data?.score || null
                }
              } catch (error) {
                console.error(`Error fetching metadata for ${item.uid}:`, error)
                return item
              }
            })
          )

          return detailedContent.sort((a, b) => b.hits - a.hits)
        } catch (error) {
          console.error(
            `Error fetching data for platform ${params.platform}:`,
            error
          )
          return []
        }
      })
    )

    const hits = initialPlatforms.reduce((acc, platform, index) => {
      acc[platform.platform] = {
        id: platform.id,
        data: results[index] || [],
        logo: platform.logo || ''
      }
      return acc
    }, {})

    const translation = (
      await import(`../locales/${locale.split('-')[0]}/common.json`)
    ).default

    const messages = {
      Genres: translation.Genres || {},
      Index: translation.Index || {},
      Navbar: translation.Navbar || {},
      Footer: translation.Footer || {},
      Cookies: translation.Cookies || {}
    }

    // Fetch country hits
    const countryHitsParams = {
      country: countryCode,
      limit: 10,
      language: language,
      append: 'credits,score'
    }

    const [moviesData, seriesData] = await Promise.all([
      searchAPI_v2.get('content/hits', {
        params: { ...countryHitsParams, type: 'movie' }
      }),
      searchAPI_v2.get('content/hits', {
        params: { ...countryHitsParams, type: 'series' }
      })
    ])

    const allCountryHits = [
      ...(moviesData?.data?.data || []),
      ...(seriesData?.data?.data || [])
    ].sort((a, b) => (b.hits || 0) - (a.hits || 0))

    // Obtener detalles con metadata/bb
    const detailedCountryHits = await Promise.all(
      allCountryHits.map(async (item) => {
        try {
          const response = await searchAPI_v2.get('content/metadata/bb', {
            params: {
              id: item.uid,
              type: item.content_type.toLowerCase(),
              append: 'credits,score',
              language: language
            }
          })
          return {
            ...item,
            score: response.data?.score || null
          }
        } catch (error) {
          console.error(`Error fetching metadata for ${item.uid}:`, error)
          return item
        }
      })
    )

    const mappedCountryHits = detailedCountryHits.map((item) => ({
      title: item.translation?.title || item.title || '',
      poster: item.poster || '',
      backdrop: item.backdrop || '',
      genre: item.genres?.[0] || '',
      year: item.year || null,
      uid: item.uid || '',
      slug: item.slug || '',
      content_type: item.content_type || '',
      hits: item.hits || 0,
      score: item.score || null
    }))

    // Filtrar duplicados
    const uniqueCountryHits = Array.from(
      new Map(
        mappedCountryHits.map((item) => [`${item.uid}-${item.title}`, item])
      ).values()
    )

    return {
      props: {
        initialPlatformHits: hits,
        countryHits: uniqueCountryHits,
        initialAllPlatforms: uniquePlatforms,
        messages
      },
      revalidate: 60 * 60 * 24
    }
  } catch (error) {
    console.error('Error in getStaticProps:', error)
    return {
      props: {
        error: 'No se pudieron cargar los datos',
        initialAllPlatforms: [],
        initialPlatformHits: {},
        countryHits: [],
        messages: {}
      },
      revalidate: 60 * 60 * 24
    }
  }
}
